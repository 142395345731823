import React from "react"

import Headshot from "../components/headshot"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{
      display: "flex"
    }}>
      <Headshot />
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
        <h2>
          <strong>Cody Robertson</strong>
        </h2>
        <p>
          <a
            href="mailto:cody@uturnr.com"
            style={{ display: `block`, marginBottom: `1rem` }}
          >
            cody@uturnr.com
          </a>
          <a href="https://www.linkedin.com/in/cody-robertson">linkedin.com/in/cody-robertson</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
